export type FilterOption = {
  label: string
  value: string
}

export enum NotAProspectValues {
  YES = 'Yes',
  NO = 'No',
}

export enum ProspectsByValues {
  CREATE = 'CREATE',
  EVENT = 'EVENT',
}

export const contacts: FilterOption[] = [
  { label: 'All', value: 'all' },
  { label: 'SMS', value: 'sms' },
  { label: 'Call', value: 'call' },
  { label: 'Email', value: 'email' },
  { label: 'Walk In', value: 'walk-in' },
  { label: 'Knockbot', value: 'chat' },
  { label: 'Scheduling', value: 'knock-schedule' },
  { label: 'Internet', value: 'internet' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Forms', value: 'form' },
]

export const dripCampaignStatuses: FilterOption[] = [
  { label: 'All', value: 'all' },
  { label: 'Live', value: 'live' },
  { label: 'Archived', value: 'archived' },
]

export const triggerTypes: FilterOption[] = [
  { label: 'All', value: 'all' },
  { label: 'Property Appointment Cancellation', value: 'cancelled_manager' },
  { label: 'Prospect Appointment Cancellation', value: 'cancelled_renter' },
  { label: 'Nurture', value: 'configurable_stale' },
  { label: 'Prospect Appointment No Show', value: 'no_show' },
  { label: 'Appointment Follow\u2011Up', value: 'post_appointment_message' },
  { label: 'Stale Prospect', value: 'stale_prospect' },
]

export interface Agent {
  agentId: number
  companyId: number
  propertyId: number
  name: string
}

export type AgentGraphQLResponse = {
  id: number
  name: string
  companyId: number
  propertyId: number
}

export type AgentSelectItem = {
  id: number
  name: string
}

// Dates
export type ReportPeriods = {
  currentPeriod: ReportDates
  priorPeriod: ReportDates
}

export type ReportDates = {
  startDate: Date
  endDate: Date
}

export type RelativeDateRangeMap = {
  value: RelativeDateRangeOptionValues
  pretty: RelativeDateRangeOptionPretty
}

export enum RelativeDateRangeOptionValues {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  MTD = 'month_to_date',
  QTD = 'quarter_to_date',
  YTD = 'year_to_date',
  LAST_WEEK_SUN_SAT = 'last_week_sun_sat',
  LAST_WEEK_MON_SUN = 'last_week_mon_sun',
  LAST_WEEK_MON_FRI = 'last_week_mon_fri',
  LAST_WEEK_FRI_THURS = 'last_week_fri_thurs',
  LAST_7_DAYS = 'last_7_days',
  LAST_7_DAYS_TODAY = 'last_7_days_today',
  LAST_14_DAYS = 'last_14_days',
  LAST_30_DAYS = 'last_30_days',
  LAST_MONTH = 'last_month',
  LAST_QUARTER = 'last_quarter',
  CUSTOM = 'custom',
  TOMORROW = 'tomorrow',
  NEXT_3_DAYS = 'next_3_days',
  NEXT_7_DAYS = 'next_7_days',
  NEXT_14_DAYS = 'next_14_days',
  NEXT_30_DAYS = 'next_30_days',
}

export enum RelativeDateRangeOptionPretty {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  MTD = 'Month-to-Date',
  QTD = 'Quarter-to-Date',
  YTD = 'Year-to-Date',
  LAST_WEEK_SUN_SAT = 'Last Week (Sun-Sat)',
  LAST_WEEK_MON_SUN = 'Last Week (Mon-Sun)',
  LAST_WEEK_MON_FRI = 'Last Week (Mon-Fri)',
  LAST_WEEK_FRI_THURS = 'Last Week (Fri-Thurs)',
  LAST_7_DAYS = 'Last 7 Days',
  LAST_7_DAYS_TODAY = 'Last 7 Days (incl. today)',
  LAST_14_DAYS = 'Last 14 Days',
  LAST_30_DAYS = 'Last 30 Days',
  LAST_MONTH = 'Last Month',
  LAST_QUARTER = 'Last Quarter',
  CUSTOM = 'Custom',
  TOMORROW = 'Tomorrow',
  NEXT_3_DAYS = 'Next 3 days',
  NEXT_7_DAYS = 'Next 7 days',
  NEXT_14_DAYS = 'Next 14 days',
  NEXT_30_DAYS = 'Next 30 days',
}

export const mapRelativeDateValues = (): RelativeDateRangeMap[] => {
  let relativeDateRangeMap: RelativeDateRangeMap[] = []
  relativeDateRangeMap.push(
    {
      value: RelativeDateRangeOptionValues.TODAY,
      pretty: RelativeDateRangeOptionPretty.TODAY,
    },
    {
      value: RelativeDateRangeOptionValues.YESTERDAY,
      pretty: RelativeDateRangeOptionPretty.YESTERDAY,
    },
    {
      value: RelativeDateRangeOptionValues.MTD,
      pretty: RelativeDateRangeOptionPretty.MTD,
    },
    {
      value: RelativeDateRangeOptionValues.QTD,
      pretty: RelativeDateRangeOptionPretty.QTD,
    },
    {
      value: RelativeDateRangeOptionValues.YTD,
      pretty: RelativeDateRangeOptionPretty.YTD,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_WEEK_SUN_SAT,
      pretty: RelativeDateRangeOptionPretty.LAST_WEEK_SUN_SAT,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_WEEK_MON_SUN,
      pretty: RelativeDateRangeOptionPretty.LAST_WEEK_MON_SUN,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_WEEK_MON_FRI,
      pretty: RelativeDateRangeOptionPretty.LAST_WEEK_MON_FRI,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_WEEK_FRI_THURS,
      pretty: RelativeDateRangeOptionPretty.LAST_WEEK_FRI_THURS,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_7_DAYS,
      pretty: RelativeDateRangeOptionPretty.LAST_7_DAYS,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_7_DAYS_TODAY,
      pretty: RelativeDateRangeOptionPretty.LAST_7_DAYS_TODAY,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_14_DAYS,
      pretty: RelativeDateRangeOptionPretty.LAST_14_DAYS,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_30_DAYS,
      pretty: RelativeDateRangeOptionPretty.LAST_30_DAYS,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_MONTH,
      pretty: RelativeDateRangeOptionPretty.LAST_MONTH,
    },
    {
      value: RelativeDateRangeOptionValues.LAST_QUARTER,
      pretty: RelativeDateRangeOptionPretty.LAST_QUARTER,
    },
    {
      value: RelativeDateRangeOptionValues.CUSTOM,
      pretty: RelativeDateRangeOptionPretty.CUSTOM,
    }
  )
  return relativeDateRangeMap
}

export const mapRelativeDateFutureValues = (): RelativeDateRangeMap[] => {
  let relativeFutureDateRangeMap: RelativeDateRangeMap[] = []
  relativeFutureDateRangeMap.push(
    {
      value: RelativeDateRangeOptionValues.TOMORROW,
      pretty: RelativeDateRangeOptionPretty.TOMORROW,
    },
    {
      value: RelativeDateRangeOptionValues.NEXT_3_DAYS,
      pretty: RelativeDateRangeOptionPretty.NEXT_3_DAYS,
    },
    {
      value: RelativeDateRangeOptionValues.NEXT_7_DAYS,
      pretty: RelativeDateRangeOptionPretty.NEXT_7_DAYS,
    },
    {
      value: RelativeDateRangeOptionValues.NEXT_14_DAYS,
      pretty: RelativeDateRangeOptionPretty.NEXT_14_DAYS,
    },
    {
      value: RelativeDateRangeOptionValues.NEXT_30_DAYS,
      pretty: RelativeDateRangeOptionPretty.NEXT_30_DAYS,
    },
    {
      value: RelativeDateRangeOptionValues.CUSTOM,
      pretty: RelativeDateRangeOptionPretty.CUSTOM,
    }
  )
  return relativeFutureDateRangeMap
}
