export const COMPANY_QUERY = `
    query Company {
      company {
        id
        name
        is_deleted
        lma_access
      }
    }
  `
