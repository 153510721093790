import React from 'react'
import { useAsync } from 'react-async' // https://www.npmjs.com/package/react-async
import { Grid } from '@material-ui/core'
import CircularProgress from 'Components/CircularProgress'

import * as auth from '../clients/auth'
import { fetchAppData } from '../clients/fetchAppData'
import useStyles from './AuthStyles'
import { useAlerts, AlertActionTypes } from '_v2/contexts/alerts'

const AuthContext = React.createContext()

const AuthProvider = (props) => {
  const classes = useStyles()
  const { alertDispatch } = useAlerts()

  // set initial auth state
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false)
  const {
    data = { user: null },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: fetchAppData,
  })

  const banners = data.user && data.user.banners

  // fetch auth data
  React.useEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true)
    }
  }, [isSettled])

  React.useEffect(() => {
    if (banners && banners.length) {
      banners.forEach((banner) => {
        alertDispatch({
          type: AlertActionTypes.ADD_ALERT,
          alert: {
            type: banner.type,
            message: banner.message,
          },
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banners])

  if (!firstAttemptFinished) {
    if (isPending) {
      return (
        <Grid
          container
          direction="row"
          spacing={1}
          justifyContent="center"
          className={classes.root}
        >
          <CircularProgress className={classes.loader} />
        </Grid>
      )
    }
    if (isRejected) {
      return (
        <div css={{ color: 'red' }}>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      )
    }
  }

  const login = (form) => auth.login(form).then(reload)

  const logout = () => auth.logout().then(reload)

  return <AuthContext.Provider value={{ data, login, logout }} {...props} />
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
