import React, { FC, useState } from 'react'
import { FormControl, TextField, Grid } from '@material-ui/core'
import Button from 'Components/Button'
import { Autocomplete } from '@material-ui/lab'
// services
import { usePropertyState, usePropertyDispatch } from './PropertiesProvider'
// types
import { IProperty, PropertyActions } from './types'
// styles
import useStyles from './styles'
import clsx from 'clsx'

type PropertyMultiSelectProps = {
  onCancel?: any
  toursGatedReport?: boolean
  callIntelGatedReport?: boolean
  callTranscriptGatedReport?: boolean
}

const ALL_OPTION = [{ id: -1, propertyName: 'All' }]

const PropertyMultiSelect: FC<PropertyMultiSelectProps> = ({
  onCancel,
  toursGatedReport,
  callIntelGatedReport,
  callTranscriptGatedReport,
}) => {
  const classes = useStyles()
  const { Filter } = PropertyActions
  // ctx
  const propertyState = usePropertyState()
  const {
    knockCrmProperties,
    selectedPropertyIds,
    toursGatedPropertyIds,
    callIntelGatedPropertyIds,
    callTranscriptGatedPropertyIds,
  } = propertyState
  const [tempProperties, setTempProperties] = useState<number[]>([])
  const propertyDispatch = usePropertyDispatch()

  const handleChange = (e: React.ChangeEvent<{}>, values: IProperty[]) => {
    if (values) {
      // -1 signifies "all"
      if (values.some((p: IProperty) => p.id === -1) || values.length === 0) {
        setTempProperties([-1])
      } else {
        // autocomplete passes in whole property object.  pass only ids to property ctx
        const newValues = values.map((p: IProperty) => p.id)
        setTempProperties(newValues)
      }
    }
  }

  const getGatedProperties = () => {
    let gatedProperties: IProperty[] = []

    if (toursGatedReport) {
      gatedProperties = knockCrmProperties.filter((p: IProperty) =>
        toursGatedPropertyIds.includes(p.id)
      )
    } else if (callIntelGatedReport) {
      gatedProperties = knockCrmProperties.filter((p: IProperty) =>
        callIntelGatedPropertyIds.includes(p.id)
      )
    } else if (callTranscriptGatedReport) {
      gatedProperties = knockCrmProperties.filter((p: IProperty) =>
        callTranscriptGatedPropertyIds.includes(p.id)
      )
    }

    return gatedProperties
  }

  const displayValues = (): IProperty[] => {
    const gatedProperties = getGatedProperties()

    if (gatedProperties.length > 0) {
      if (gatedProperties.length === selectedPropertyIds.length && tempProperties.length === 0) {
        return []
      }
      return gatedProperties.filter((p: IProperty) =>
        (tempProperties.length ? tempProperties : selectedPropertyIds).includes(p.id)
      )
    } else {
      if (knockCrmProperties.length === selectedPropertyIds.length && tempProperties.length === 0) {
        return []
      }
      return knockCrmProperties.filter((p: IProperty) =>
        (tempProperties.length ? tempProperties : selectedPropertyIds).includes(p.id)
      )
    }
  }

  const handlePropertySave = () => {
    propertyDispatch({
      type: Filter,
      selectedPropertyIds: tempProperties.length ? tempProperties : selectedPropertyIds,
    })
    setTempProperties([])
  }

  const handleOptions = () => {
    let options: IProperty[] = []
    if (toursGatedReport) {
      options = knockCrmProperties.filter((p: IProperty) => toursGatedPropertyIds.includes(p.id))
    } else if (callIntelGatedReport) {
      options = knockCrmProperties.filter((p: IProperty) =>
        callIntelGatedPropertyIds.includes(p.id)
      )
    } else if (callTranscriptGatedReport) {
      options = knockCrmProperties.filter((p: IProperty) =>
        callTranscriptGatedPropertyIds.includes(p.id)
      )
    } else {
      options = knockCrmProperties
    }

    return ALL_OPTION.concat(options)
  }

  const allPlaceholderShouldBeShown = () => {
    const gatedProperties = getGatedProperties()

    if (
      selectedPropertyIds.includes(-1) ||
      selectedPropertyIds.length === 0 ||
      tempProperties.includes(-1)
    ) {
      return true
    }
    if (toursGatedReport || callIntelGatedReport || callTranscriptGatedReport) {
      return gatedProperties.length === selectedPropertyIds.length
    } else {
      return selectedPropertyIds.length === knockCrmProperties.length
    }
  }

  return (
    <>
      <FormControl className={classes.formControl}>
        <Autocomplete
          multiple
          autoComplete
          autoHighlight
          onChange={handleChange}
          options={handleOptions()}
          getOptionLabel={(p: IProperty) => p.propertyName}
          disableCloseOnSelect
          value={displayValues()}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Properties"
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder={allPlaceholderShouldBeShown() ? 'All' : ''}
            />
          )}
        />
      </FormControl>
      <Grid
        container
        spacing={4}
        justifyContent="flex-end"
        className={classes['modal__action-bar']}
        style={{ padding: '12px' }}
      >
        <Button
          variant="outlined"
          className={clsx(classes.button__report_action, classes['modal__save-button'])}
          onClick={handlePropertySave}
        >
          Save
        </Button>
        <Button
          variant="text"
          className={classes['modal__cancel-button']}
          onClick={() => onCancel && onCancel()}
        >
          Cancel
        </Button>
      </Grid>
    </>
  )
}

export default PropertyMultiSelect
